<template>
  <el-dialog
    v-model='dialogVisible'
    title='评论回复列表'
    width='75%'
  >
    <el-table :data='tableData' style='width: 100%' v-loading='loading'>
      <el-table-column prop='name' label='评论人'>
        <template #default='{ row }'>
          <span v-if='row.type===1'>我自己</span>
          <span v-else>
              <el-tag size='small' v-if='row.type===2' type='success'>房东</el-tag>
            <el-tag size='small' v-if='row.type===3'>企业</el-tag>
             <el-tag size='small' v-if='row.type===4' type='warning'>平台</el-tag>
            {{ row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop='comment' label='评论内容' />
      <el-table-column prop='comment_tag' label='评论标签'>
        <template #default='{ row }'>
          <span v-if="row.comment_tag && row.comment_tag!=''">{{ JSON.parse(row.comment_tag).join(',') }}</span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <el-table-column prop='grade' label='评分' />
      <el-table-column prop='create_time' label='评论时间' />
      <el-table-column prop='publishName' label='操作' width='160'>
        <template #default='{ row }'>
          <el-button
            link
            size='mini'
            type='primary'
            v-if="row.scans && row.scans != '[]'"
            @click='showImageDialog(row)'
          >查看附件
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-model='imageDialogVisible'
      title='附件列表'
      width='75%'
    >
      <el-image
        style='width: 200px; height: 200px;margin-left: 10px;margin-top: 10px'
        v-for='(item,index) in imageList'
        :key='index'
        :src='item'
        :preview-src-list='imageList'
        fit='cover'
      />
    </el-dialog>

  </el-dialog>

</template>

<script setup>
import {
  reactive,
  ref,
  onMounted,
  computed,
  defineExpose,
  defineEmits
} from 'vue'
import { useStore } from 'vuex'
import { getCommentDetailApi, saveCommentAPI } from '@/api/appointComment'

const store = useStore()

const dialogVisible = ref(false)
const tableData = ref([])
const loading = ref(false)
const editRow = ref({})
const imageDialogVisible = ref(false)

async function fetchCommentInfo() {
  try {
    loading.value = true
    const req = await getCommentDetailApi(editRow.value.commentId)
    tableData.value = req
  } finally {
    loading.value = false
  }
}

const imageList = ref([])
const showImageDialog = (row) => {
  if (row.scans && row.scans != '[]') {
    imageDialogVisible.value = true
    imageList.value = JSON.parse(row.scans).map(item => {
      return store.state.app.minioUrl + item.smjsj
    })
  }
}
// 打开弹窗
const openModel = async (row) => {
  dialogVisible.value = true
  editRow.value = row
  tableData.value = []
  await fetchCommentInfo()
}
defineExpose({
  openModel
})
</script>

<style scoped>

</style>
