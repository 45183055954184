<template>
  <el-dialog
    v-model='dialogVisible'
    :title='formInline.commentType===1?"发起评论":"发起投诉"'
    width='65%'
    :before-close='handleClose'
  >
    <el-form :model='formInline' class='demo-form-inline'>

      <el-form-item label-width='70px' label='房源坐落'>
        <el-input v-model='formInline.houseLocation' disabled placeholder='' />
      </el-form-item>
      <!--      <div class='titleBox'>评论类型</div>
            <el-radio-group v-model='formInline.commentType'>
              <el-radio label='2'>评论</el-radio>
              <el-radio label='1'>投诉</el-radio>
            </el-radio-group>-->
      <div class='titleBox'>本次交易评分</div>
      <el-rate v-model='formInline.grade' :colors='colors' />
      <div class='titleBox'>本次交易评价标签</div>
      <div>
        <el-checkbox-group v-model='formInline.commentTag' size='large'>
          <el-checkbox-button
            v-for='option in citiesTemp'
            :key='option'
            :label='option'
          >
            {{ option }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class='textarea'>
        <el-input
          v-model='formInline.comment'
          maxlength='100'
          placeholder='请输入评价内容'
          show-word-limit
          type='textarea'
        />
      </div>
      <div class='titleBox'>附件上传</div>
      <nesi-upload
        @handleFile='handleCertFile'
        @deleteFile='deleteCertFile'
        :list='certList'
        :limit='12'
      ></nesi-upload>
    </el-form>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='dialogVisible = false'>取消</el-button>
        <el-button type='primary' @click='add'>提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  reactive,
  ref,
  onMounted,
  computed,
  defineExpose,
  defineEmits
} from 'vue'
import { useStore } from 'vuex'
import { getCommentDetailApi, saveCommentAPI } from '@/api/appointComment'
import NesiUpload from '@/components/NesiUpload/index.vue'

const certList = ref([])
const tempCertList = ref([])
const handleCertFile = (data) => {
  console.log(data)
  certList.value.push({
    smjmc: data.name,
    smjsj: data.path,
    uid: data.uid,
    url: data.minio + data.path
  })
}
const deleteCertFile = (data) => {
  certList.value = certList.value.filter((item) => item.uid !== data.uid)
}

const store = useStore()
const editRow = ref({})
const emit = defineEmits(['success'])
onMounted(() => {
})
// 当前角色
const identity = computed(() => {
  if (store.state.user.isTenant) {
    return 1
  } else {
    return 0
  }
})
const getFormInline = () => {
  return {
    houseLocation: '',
    id: '',
    grade: '',
    comment: '',
    commentTag: [],
    houseId: '',
    objectAccount: '',
    objectName: '',
    num: identity.value,
    type: 1,
    commentType: '1',
    commentId: ''
  }
}
// 表单事件
const formInline = reactive(getFormInline())
const reset = () => {
  Object.assign(formInline, getFormInline())
  houseNumber.value = ''
  certList.value = []
}
const houseNumber = ref()

// 添加
async function add() {
  const obj = JSON.parse(JSON.stringify(formInline))
  obj.commentTag = JSON.stringify(obj.commentTag)
  if (formInline.commentType === 2 && (!certList.value || certList.value.length === 0)) {
    ElMessage({
      type: 'error',
      message: '投诉业务，请先上传附件证明材料！'
    })
    return
  }

  if (certList.value && certList.value.length) {
    obj.scans = certList.value
  }

  try {
    await saveCommentAPI(obj)
    ElMessage({
      message: '添加成功',
      type: 'success'
    })
    emit('success')
    dialogVisible.value = false
    reset()
  } catch (error) {
  } finally {
  }
}

// 评分
const colors = ref(['#99A9BF', '#F7BA2A', '#FF9900'])
// 多选框
const cities = ['与图片描述一致', '服务周到', '房源可靠', '服务不周']

const cities2 = ['与图片描述不一致', '服务差', '房源不真实', '服务不周']

const citiesTemp = computed(() => {
  return formInline.commentType === 1 ? cities : cities2
})

// 多行文本
// model事件
const dialogVisible = ref(false)
const handleClose = (done) => {
  ElMessageBox.confirm('您确定要取消当前操作并关闭对话框吗?')
    .then(() => {
      done()
      reset()
    })
    .catch(() => {
      // catch error
    })
}

// 打开弹窗
const openModel = async (row, type) => {
  dialogVisible.value = true
  reset()
  editRow.value = row
  formInline.houseLocation = row.house_location
  formInline.commentId = row.id
  formInline.commentType = type
  formInline.type = row.appointmentType === 4 ? 3 : row.appointmentType
}
defineExpose({
  openModel
})
</script>

<style lang='scss' scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}

.titleBox {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f3f3f3;
  font-size: 14px;
  color: #333;
  border-radius: 2px;
  margin: 20px 0;
}

.textarea {
  margin: 10px 0;
}

.paginationStyle {
  width: 100%;
  height: 100px;
  background-color: #333;
  display: flex;
  justify-content: flex-end;
}
</style>
