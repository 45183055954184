<template>
  <div style='height: 100%; width: 100%'>
    <nesi-user-common :title='`预约评价`'>
      <!--      <template #button>
              <div class='btn-wrapper'>
                <el-button type='primary' plain @click='open'>添加预约评价</el-button>
              </div>
            </template>-->
      <template #query>
        <el-form :inline='true' :model='form' class='demo-form-inline'>

          <!--          <el-form-item label='房源坐落'>
                      <el-input
                        v-model='form.location'
                        placeholder='请输入房源坐落'
                        :clearable='true'
                      />
                    </el-form-item>-->
          <!-- <el-form-item label="核验状态">
            <el-select v-model="form.houseStauts" placeholder="核验状态">
              <el-option label="不通过" value="0" />
              <el-option label="核验中" value="1" />
              <el-option label="核验通过" value="2" />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type='primary' @click='getAppointment'>刷新</el-button>
          </el-form-item>
        </el-form>

        <el-table :data='tableData' style='width: 100%' v-loading='loading'>
          <el-table-column prop='house_location' label='坐落' width='180' />
          <el-table-column prop='publishName' label='交易对象' width='160'>

            <template #default='{ row }'>
              <span v-if='row.agentCompany'>{{ row.agentCompany }}</span>
              <span v-else>{{ row.publishName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop='grade' label='交易类型'>
            <template #default='{ row }'>
              <!--  1 预约看房(老数据没有的默认为预约看房)2 合同预约 3 二手房合同预约   -->
              <span v-if='row.appointmentType===1'>预约看房</span>
              <span v-else-if='row.appointmentType===2'>合同预约</span>
              <span v-else-if='row.appointmentType===3'>二手房合同预约</span>
              <span v-else>在线网签</span>
            </template>
          </el-table-column>
          >
          <el-table-column prop='comment' label='是否评论'>
            <template #default='{ row }'>
              <p v-if='row.commentId && !row.isCanComment'>
                <a
                  v-if='row.commentId'
                  @click='openTableModal(row,1)'
                  style='color: #409eff;margin-right: 12px'
                >查看评论
                </a>

              </p>
              <p v-if='row.commentId && !row.isCanComplain'>
                <a
                  v-if='row.commentId'
                  @click='openTableModal(row,2)'
                  style='color: #fa153b;margin-right: 12px;margin-top: 8px'
                >查看投诉
                </a>
              </p>
              <p v-if='!row.commentId'>未评论</p>
            </template>
          </el-table-column>
          <el-table-column prop='create_time' label='创建时间' />
          <el-table-column
            prop='address'
            fixed='right'
            label='操作'
            width='160'
          >
            <template #default='{ row }'>

              <a
                v-if='row.isCanComment'
                type='primary'
                size='small'
                style='color:#409eff;margin-right: 12px'

                @click='open(row,1)'
              >去评论
              </a>
              <a
                v-if='row.isCanComplain'
                type='primary'
                size='small'
                style='color: #faa434;'
                @click='open(row,2)'
              >去投诉
              </a>
              <span v-if='!row.isCanComplain && !row.isCanComment'>---</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :page-size='pageSize'
          v-model:current-page='pageNo'
          background
          layout='prev, pager, next'
          :total='total'
          @current-change='currentChange'
        />
      </template>
      <template #content></template>
    </nesi-user-common>
    <addModel @success='success' ref='model'></addModel>
    <CommentTableModel ref='commentTable'></CommentTableModel>
  </div>
</template>

<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import addModel from './module/addModel.vue'
import CommentTableModel from './module/CommentTableModel.vue'

import { findOrderListAPI, remAppointment } from '@/api/appointComment.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, onMounted, defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
defineComponent({
  NesiUserCommon
})
onMounted(() => {
  getAppointment()
})
// 当前角色
const identity = computed(() => {
  if (store.state.user.isTenant) {
    return 1
  } else {
    return 0
  }
})
const tableData = ref()
const pageNo = ref(1)
const pageSize = ref(10)
const total = ref()
const loading = ref(false)

// 分页
function currentChange() {
  getAppointment()
}

// 获取表单
async function getAppointment() {
  const obj = {
    pageNo: pageNo.value,
    pageSize: pageSize.value
  }
  try {
    loading.value = true
    const res = await findOrderListAPI(obj)
    // console.log(res)
    tableData.value = res.records.map(item => {
      item.isCanComment = !item.commentType || item.commentType.indexOf('-1') === -1
      item.isCanComplain = !item.commentType || item.commentType.indexOf('-2') === -1
      return item
    })
    total.value = res.total
  } finally {
    loading.value = false
  }
}

// 添加成功事件
function success() {
  getAppointment()
}

// 添加表单模块
const model = ref(null)
const commentTable = ref(null)

function open(row, type) {
  model.value.openModel(row, type)
}

function openTableModal(row, type) {
  // 获取真实实际ID值
  // 返回格式：【id....-1,id....-2】，其中”-1“，’-2‘为实际类型
  // 举例ID: 0398a9c49d5943a1a3b03f608a946d26-1,580e3923eae74c39b2626413c37a6ce7-2
  const ids = row.commentType.split(',')
  const getterVal = ids.filter(item => item.indexOf(`-${type}`) !== -1)
  const id = getterVal[0].split('-')[0]
  commentTable.value.openModel({ ...row, commentId: id })
}
</script>

<style lang='scss' scoped>
.link {
  width: 70px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.required {
  color: #ff0000;
}

::v-deep .el-collapse-item__header {
  background: #f5f7fa;
  width: 100%;
  padding: 0 10px;
}

::v-deep .el-collapse-item__content {
  margin: 22px 0;
  padding: 0 10px;
}

::v-deep .el-table {
  font-size: 13px;
}

.w-80 {
  width: 80px;
}

.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

.indent-wrapper {
  p {
    margin-left: 20px;
  }
}

.w-p-48 {
  width: 48%;
}

::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>
